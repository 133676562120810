import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import clsx from 'clsx'
import {checkIsActive, KTSVG} from '../../../helpers'
import {flagEnableJumpseat} from '../../../../middleware/flags'

export function MenuInner() {
  return (
    <>
      <MenuInnerWithSub
        title='Crewbases'
        to='/crew'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuItem to='/airport/mdsd' title='Las Americas' hasBullet={true} />
        <MenuItem to='/airport/mdpc' title='Punta Cana' hasBullet={true} />
        <MenuItem to='/airport/mdlr' title='La Romana' hasBullet={true} />
        <MenuItem to='/airport/mdjb' title='El Higuero' hasBullet={true} />
      </MenuInnerWithSub>

      <MenuItem to='/roster' title='Roster' />

      <MenuInnerWithSub
        title='Flight Planning'
        to='/crafted'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuItem to='/schedules' title='Schedules' hasBullet={true} />
        <MenuItem to='/pbs' title='Trip Request' hasBullet={true} />
        <MenuItem to='/bid-lines' title='My Trips' hasBullet={true} />
        <MenuItem to='/manage' title='Booked Flights' hasBullet={true} />
        <MenuItem to='/route-map' title='Route Map' hasBullet={true} />
        {flagEnableJumpseat() && <MenuItem to='/jumpseat' title='Jumpseat' hasBullet={true} />}
        <MenuItem to='/route-changelog' title='Route Changelog' hasBullet={true} />
      </MenuInnerWithSub>

      <MenuItem to='/flights' title='Logbook' />
      <MenuItem to='/live-map' title='Live Map' />
      <MenuItem to='/events' title='Events' />
      <div className='menu-item me-lg-1'>
        <a
          className={clsx('menu-link py-3')}
          href={
            'https://github.com/Rotate-Group/acars-release/releases/download/v1.1.4/Titan-Tracker-Setup-1.1.4.exe'
          }
        >
          <span className='menu-icon '>
            <KTSVG
              path={'/media/icons/duotune/files/fil021.svg'}
              className='text-white svg-icon-1'
            />
          </span>

          <span className='menu-title'>Titan Tracker (ACARS)</span>
        </a>
      </div>
      {/* <MenuItem to='/apps/chat/group-chat' title='Challenges' />
      <MenuItem to='/apps/chat/group-chat' title='Statistics' />
      <MenuItem to='/apps/chat/group-chat' title='Events' />
      <MenuItem to='/apps/chat/group-chat' title='Resources' /> */}
    </>
  )
}
